import React from 'react';
import Gallery from '@tra-sg/gatsby-theme-c360-portal/src/components/Gallery';

const NotificationPage = (props) => (
  <Gallery
    metaTitle="Notification | c360"
    title="Notification"
    dashboardName="notification-page"
    pageComponent={props}
  />
);

export default NotificationPage;
